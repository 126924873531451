import Image from "next/legacy/image";
import Link from "next/link";
import Head from "next/head";
import { Grid } from "@hiltermann/components/ui";

interface ErrorPageProps {
  statusCode: number;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ statusCode }) => {
  const title = statusCode === 404 ? "Niet gevonden" : "Er is iets fout gegaan";
  const message = (
    <>
      {statusCode === 404
        ? "We hebben ons best gedaan maar het lijkt erop dat deze pagina niet (meer) bestaat of misschien verhuisd is. "
        : "We hebben ons best gedaan, maar het lijkt erop dat deze pagina niet werkt. "}
      Je kunt natuurlijk altijd terugkeren naar de{" "}
      <Link href="/" className="text-secondary-aubergine font-sans font-semibold underline">
        homepagina
      </Link>{" "}
      of het later opnieuw proberen.
    </>
  );

  return (
    <>
      <Head>
        <title>{statusCode === 404 ? "Pagina niet gevonden" : "Er is iets fout gegaan"}</title>
      </Head>
      <div className="bg-grey-light pb-32">
        <Grid className="relative">
          <div className="tablet:block absolute left-[-10%] top-1/2 hidden -translate-y-1/2">
            <h6 className="font-sans text-[33vw] font-bold text-white">{statusCode}</h6>
          </div>
          <h4 className="text-p text-secondary-aubergine tablet:col-span-4 tablet:mb-10 tablet:mt-[82px] tablet:text-h3-sm relative col-span-full row-start-1 mt-12 line-clamp-1 font-serif tracking-wide">
            {title}
          </h4>
          <div className="tablet:col-span-6 relative col-span-full row-start-2">
            <h2 className="text-h1-sm tablet:mb-10 tablet:text-h1-md tablet:line-clamp-2 font-sans font-bold tracking-wide">
              Oeps, we moeten even{" "}
              <u className="text-secondary-aubergine no-underline">schakelen</u>
            </h2>
            <p className="text-p tablet:mb-10 tablet:line-clamp-3 font-serif tracking-wide">
              {message}
            </p>
          </div>
          <div className="tablet:col-span-5 tablet:col-start-8 tablet:row-start-2 tablet:mt-0 relative col-span-full mx-auto mt-16 aspect-square w-5/6">
            <Image
              src="/images/error-page-background.png"
              layout="fill"
              alt="error background image"
            />
          </div>
        </Grid>
      </div>
    </>
  );
};

export default ErrorPage;
